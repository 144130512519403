<div style="min-height: 75vh">
  <div class="mainb mb-4">
    <div class="container">
      <h1 class="titleblog mb-0 ml-3">Blog</h1>
      <div class="d-flex justify-content-between align-items-center mt-4">
        <div class="d-flex align-items-center menu-cat ml-3" routerLink="" style="cursor:pointer">
          <img src="assets/img/arrow-l-w.svg" class="mr-2" alt="arrow">
          <h5 class="back mb-0">Indietro</h5>
        </div>
        <div class="d-flex align-items-center">
          <p class="mr-3 mb-0 d-none d-md-block">Condividi su:</p>
          <ul class="d-flex justify-content-end align-items-center">
            <li class="mr-2">
              <a (click)="social('facebook')"><img src="assets/img/facebook-w.svg" alt="facebook" height="26"></a>
            </li>
            <li class="mr-2">
              <a (click)="social('linkedin')"><img src="assets/img/linkedin-w.svg" alt="linkedin" height="28"></a>
            </li>
            <li class="mr-2">
              <a (click)="social('email')"><img src="assets/img/email-w.svg" alt="email" height="31"></a>
            </li>
            <li class="mr-2">
              <a (click)="social('whatsapp')"><img src="assets/img/wtsp-w.svg" alt="whatsapp" height="27"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <!-- detail news  -->
      <div class="col-lg-12 px-4 px-md-5" *ngIf="idBlog!=null">
        <div class="row">
          <div class="col-lg-12 blog">
            <div class="row mt-2 mt-md-4">
              <div class="col-lg-6">
                <span class="d-block h5 mb-3">{{detail[0]?.Blog.DtInsert | date: 'dd/MM/yyyy'}}</span>
                <h1 class="titolo mb-3">{{detail[0]?.Blog.Title}}</h1>
                <h2 class="shortdesc"> {{detail[0]?.Blog.ShortDescription}}</h2>
              </div>
              <div class="col-lg-6">
                <img src="{{detail[0]?.Blog.Base64}}" class="img-fluid img-blog mb-4 m-0"
                  *ngIf="detail[0]?.Blog.Base64">
              </div>
            </div>


            <p class="my-4 bodyy" [innerHTML]="blogBody"></p>
            <div *ngIf="detail[0]?.Attachments.length!=0">
              <div *ngFor="let item of detail[0]?.Attachments">
                <img [src]="item.Base64" [alt]="item.Caption"
                  *ngIf="item.FileExt=='.jpg' ||item.FileExt=='.jepg' ||item.FileExt=='.png' ">
              </div>
            </div>
            <div *ngIf="detail[0]?.Attachments.length!=0">
              <ng-container *ngFor="let item of detail[0]?.Attachments">
                <div class="d-flex attach align-items-center mb-2"
                  *ngIf="item.FileExt!='.jpg' && item.FileExt!='.jepg' &&item.FileExt!='.png' ">
                  <a href="{{item.Link}}" target="_blank">{{item.Caption}}</a>
                  <span>{{item.Link}}</span>
                </div>
              </ng-container>
            </div>

            <div class="d-flex align-items-center mt-4 mb-5">
              <p class="mr-3 mb-0">Condividi su:</p>
              <ul class="d-flex justify-content-end align-items-center">
                <li class="mr-2">
                  <a (click)="social('facebook')"><img src="assets/img/facebook.svg" alt="facebook" height="26"></a>
                </li>
                <li class="mr-2">
                  <a (click)="social('linkedin')"><img src="assets/img/linkedin.svg" alt="linkedin" height="28"></a>
                </li>
                <li class="mr-2">
                  <a (click)="social('email')"><img src="assets/img/email.svg" alt="email" height="31"></a>
                </li>
                <li class="mr-2">
                  <a (click)="social('whatsapp')"><img src="assets/img/wtsp.svg" alt="whatsapp" height="27"></a>
                </li>
              </ul>
            </div>

          </div>
        </div>
        <div class="d-flex flex-wrap justify-content-center justify-content-md-between align-items-center main-f mt-3">
          <button class="btn btn-w w-auto p-0 mr-auto font-weight-bold text-left" *ngIf="detail[0]?.Blog.IdPrevious"
            (click)="generateBlogLink(detail[0]?.Blog.IdPrevious, detail[0]?.Blog.TitlePrevious)"> <img loading="lazy"
              src="assets/img/arrow-l.svg" alt="arrow" height="18" width="19"
              class="my-0 m-2">
              <!-- {{detail[0]?.Blog.TitlePrevious}} -->
            </button>

          <button class="btn btn-w w-auto p-0 ml-auto font-weight-bold text-right" *ngIf="detail[0]?.Blog.IdNext"
            (click)="generateBlogLink(detail[0]?.Blog.IdNext, detail[0]?.Blog.TitleNext)">
            <!-- {{detail[0]?.Blog.TitleNext}} -->
            <img loading="lazy" src="assets/img/arrow-r.svg" height="18" width="19" class="my-0 m-2">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div itemscope itemtype="https://schema.org/NewsArticle" style="position: absolute;bottom: 0;visibility: hidden;z-index: -10;">
  <div itemprop="headline">{{detail[0]?.Blog.Title}}</div>
  <!-- <meta itemprop="image" content="{{detail[0]?.Blog.Base64}}" />
  <meta itemprop="image" content="{{detail[0]?.Blog.Base64}}" /> -->
  <!--<img itemprop="image" src="https://example.com/photos/16x9/photo.jpg" /> -->
  <div>
    <span itemprop="datePublished">
      {{detail[0]?.Blog.DtInsert | date: 'yyyy-MM-ddTHH:mm:ssZ'}}
    </span>
    (last modified
    <span itemprop="dateModified">
      {{detail[0]?.Blog.DtUpdate | date: 'yyyy-MM-ddTHH:mm:ssZ'}}
    </span>
    )
  </div>
  <div>
    <span itemprop="author" itemscope itemtype="https://schema.org/Organization">
      <a itemprop="url" href="https://www.itd-italia.com/">
        <span itemprop="name">ITD srl</span>
      </a>
    </span>
  </div>
</div>
