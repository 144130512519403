<header>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
      <a class="navbar-brand" href="https://www.itd-italia.com/"><span class="logo"></span></a>

      <button class="navbar-toggler collapsed" type="button" data-toggle="collapse"
          data-target="#collapsibleNavbar">
          <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
              <li class="nav-item">
                  <a class="nav-link" href="https://www.itd-italia.com/software-gestionali.html">Software Gestionali</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="https://www.itd-italia.com/web-marketing.html">Web Marketing</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="https://www.itd-italia.com/reti-aziendali.html">Reti Aziendali</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="https://www.itd-italia.com/impianti-tecnologici.html">Impianti Tecnologici</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="https://www.itd-italia.com/chi-siamo.html">Chi Siamo</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="https://www.itd-italia.com/assistenza.html">Assistenza</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link active" href="#">Blog</a>
              </li>
              <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                      Contatti
                  </a>
                  <div class="dropdown-menu">
                      <a class="dropdown-item" href="https://www.itd-italia.com/contatti.html">Contattaci</a>
                      <a class="dropdown-item" href="https://www.itd-italia.com/lavoro.html">Lavora con noi</a>
                  </div>
              </li>
          </ul>
      </div>
  </nav>
</header>

<router-outlet #o="outlet"></router-outlet>

<section class="footer mt-0">
  <div class="row">
      <div class="col-lg-6">
          <h5 class="text-uppercase">Contatti</h5>
          <div class="d-flex flex-wrap">
              <div>
                  <p>
                      ITD Srl<br>
                      Via Torre degli Agri 45, 24030<br>
                      Almenno San Bartolomeo (BG)<br>
                  </p>
              </div>
              <div class="ml-5">
                  <p>
                     <a onclick="return gtag_report_conversion('tel:+39035549015');"  href="tel:+39035549015" class="text-white">
                          Tel. +39 035 549015</a><br>
                      C.F. e P.IVA 04029630169 | REA BG-429377<br>
                      <a href="https://www.itd-italia.com/privacy.html" style="color:white" target="blank">Privacy & Cookie Policy</a>
                  </p>
              </div>
          </div>
      </div>
      <div class="col-lg-6">
          <div class="d-flex flex-wrap">
              <div>
                  <h5 class="text-uppercase">SEGUICI</h5>
                  <ul class="list-inline mb-0">
                      <li><a href="https://www.instagram.com/itd_italia/" target="blank"><img width="20"
                                  src="https://www.itd-italia.com/assets/img/instagram.svg" alt="instagram itd srl"></a></li>
                      <li><a href="https://www.linkedin.com/company/itd-srl/"
                              target="blank"><img width="20" src="https://www.itd-italia.com/assets/img/linkedin.svg" alt="linkedin itd srl"></a></li>
                      <li><a href="https://medium.com/blog-itd" target="blank"><img width="1"
                                  style="margin-top: 10px;" src="https://www.itd-italia.com/assets/img/medium.svg" alt="itd news medium"></a></li>
                      <li><a href="https://www.youtube.com/channel/UCsqBvVu96vn9dQFz_Y6qJkA" target="blank"><img
                                  width="20" src="https://www.itd-italia.com/assets/img/youtube.svg" alt="youtube itd srl"></a></li>
                      <li><a href="https://www.facebook.com/ITDItalia/" target="blank"><img width="20"
                                  src="https://www.itd-italia.com/assets/img/facebook.svg" alt="facebook itd srl"></a></li>
                  </ul>
              </div>
              <div class="ml-auto logo-footer">
                  <a href="https://www.itd-italia.com">
                      <img src="https://www.itd-italia.com/assets/img/logo-w.svg" alt="Logo Footer">
                  </a>
                  <h5 class="mt-2">Italian Technology Developers</h5>
              </div>
          </div>
      </div>
  </div>
</section>
