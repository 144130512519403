import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Service } from '../service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import * as moment from 'moment';


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent {

  constructor(public service: Service,private route: ActivatedRoute,private router:Router, private title: Title,private sanitizer: DomSanitizer, private metaService: Meta, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    // this.service.getAppsettings();
    // setTimeout(() => {
      var date = moment(new Date(), "YYYY-MM-DD");
      var hour = new Date().getHours();
      if (isPlatformBrowser(this.platformId)) {
        if (localStorage.getItem("tokenBlog") !== null) {
          var DateToken = JSON.parse(localStorage.getItem("DtTokenBlog")); // Check the key here
          if (DateToken && moment(DateToken, 'YYYY-MM-DD') > date) {
            this.service.updatetoken(JSON.parse(localStorage.getItem("tokenBlog")));
            this.main();
          } else if (DateToken && moment(DateToken, 'YYYY-MM-DD') === date) {
            if (new Date(DateToken).getHours() > hour) {
              this.service.updatetoken(JSON.parse(localStorage.getItem("tokenBlog")));
              this.main();
            } else {
              this.getToken();
            }
          } else {
            this.getToken();
          }
        } else {
          this.getToken();
        }
      } else {
        this.getToken();
      }
    // }, 300);
  }

  token: any = [];
  getToken() {
    this.service.getToken().subscribe(
      res => {
        this.token = res;
        this.service.updatetoken(this.token.Token);
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("tokenBlog", JSON.stringify(this.token.Token));
          localStorage.setItem("DtTokenBlog", JSON.stringify(moment(this.token.DtExpire.substring(0, 15), 'YYYY-MM-DD:hh:mm')));
        }
        this.main();
      }, error => {
        // You can access status:
        console.log(error.message);
      }
    )
  }

  main() {
    this.route.paramMap.subscribe(params => {
      this.idBlog = params.get('id');
      if (this.idBlog != null) {
        this.GetBlogDetails();
      }
    })
  }

  idBlog = null;


  detail: any = [];
  blogBody: SafeHtml;
  GetBlogDetails() {
    this.service.GetBlogDetails(this.idBlog).subscribe(
      res => {
        this.detail = res;
        this.blogBody = this.sanitizer.bypassSecurityTrustHtml(this.detail[0]?.Blog.Body);
        //SEO
        this.title.setTitle(this.detail[0].Blog.Title);
        this.metaService.addTag({ name: 'title', content: this.detail[0].Blog.Title });
        this.metaService.addTag({ property: 'og:title', content: this.detail[0].Blog.Title });
        this.metaService.addTag({ name: 'description', content: this.detail[0].Blog.ShortDescription });
        this.metaService.addTag({ property: 'og:description', content: this.detail[0].Blog.ShortDescription });
        // this.metaService.addTag({ name: 'image', content: this.detail[0].Blog.Base64 });
        // this.metaService.addTag({ property: 'og:image', content: this.detail[0].Blog.Base64 });
        this.metaService.addTag({ property: 'og:url', content: this.url });
        // Fin SEO
      }
    )
  }


  url = isPlatformBrowser(this.platformId) ? window.location.href : '';

  social(a) {
    let pinterest = "http://pinterest.com/pin/create/button/" +
      "?url=" + this.url +
      "&media=" + this.detail[0].Blog.Base64 +
      "&description=" + this.detail[0].Blog.ShortDescription;
    let facebook = 'https://www.facebook.com/sharer/sharer.php?u=' + this.url;
    let twitter = 'https://twitter.com/share?text=' + this.detail[0].Blog.Title + '&url=' + this.url;
    let linkedin = 'https://www.linkedin.com/sharing/share-offsite/?url=' + this.url;

    // Use a link for WhatsApp
    let whatsapp = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(this.detail[0].Blog.Title + ' - ' + this.url);

    // Directly navigate to mailto link for Email
    let email = 'mailto:?subject=' + encodeURIComponent(this.detail[0].Blog.Title) + '&body=' + encodeURIComponent(this.url);

    let targetLink = '';
    if (a === 'pinterest') {
      targetLink = pinterest;
    }
    if (a === 'twitter') {
      targetLink = twitter;
    }
    if (a === 'facebook') {
      targetLink = facebook;
    }
    if (a === 'linkedin') {
      targetLink = linkedin;
    }
    if (a === 'whatsapp') {
      targetLink = whatsapp;
    }
    if (a === 'email') {
      targetLink = email;
      window.location.href = targetLink;
      return;
    }

    let params = `width=500,height=400,left=100,top=100`;
    if (isPlatformBrowser(this.platformId)) {
      window.open(targetLink, '_blank', params);
    }
  }

  indietro() {
    if (isPlatformBrowser(this.platformId)) {
      window.history.back()
    }
  }

  generateBlogLink(blogId: number, blogTitle: string) {
    const formattedTitle = blogTitle.replace(/[^\w\s-]/g, '') // Remove non-alphanumeric characters except spaces and hyphens
                                     .replace(/\s+/g, '-') // Replace spaces with hyphens
                                     .replace(/--+/g, '-') // Replace consecutive hyphens with single hyphen
                                     .replace(/^-+|-+$/g, '') // Remove leading or trailing hyphens
                                     .toLowerCase(); // Convert to lowercase
    const encodedTitle = encodeURIComponent(formattedTitle);
    this.router.navigate([`${blogId}/${encodedTitle}`]);
  }


}
