<div style="min-height: 75vh">
  <div class="mainb mb-5">
    <div class="container">
      <h1 class="titleblog mb-0">Blog</h1>
      <div class="d-flex flex-wrap align-items-center main-f">
        <span class="result mr-auto"><b>{{totalblogs}}</b> <span class="d-none d-md-inline">Articoli</span></span>

        <input type="text" class="mr-2 searchp" placeholder="Cerca..." [(ngModel)]="searchValue"
          (ngModelChange)="GetTotalblogs()" (keyup.enter)="GetTotalblogs()">

        <select class="w-auto mr-2 my-2 my-lg-0" [(ngModel)]="blogCat" (change)="GetTotalblogs()" *ngIf="openmenu">
          <option value="">Tutte le categorie</option>
          <option value="{{item.Id}}" *ngFor="let item of Categoriesblogs">{{item.Description}}</option>
        </select>

        <select class="w-auto mr-2 my-2 my-lg-0" [(ngModel)]="yearSelected" (ngModelChange)="GetTotalblogs()"
          *ngIf="openmenu">
          <option value="">Tutti gli anni</option>
          <option value="{{currentYear}}">{{currentYear}}</option>
          <option value="{{currentYear - 1}}">{{currentYear - 1}}</option>
        </select>

        <select class="w-auto mr-2 my-2 my-lg-0" [(ngModel)]="dateFiltred" (ngModelChange)="GetTotalblogs()"
          *ngIf="openmenu">
          <option value="asc">Data crescente</option>
          <option value="desc">Data decrescente</option>
        </select>

        <button class="btnmenu" (click)="openmenu=!openmenu"><img src="assets/img/menu.svg"></button>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <!-- news -->
      <!-- <div class="loader" *ngIf="loader==true"></div> -->
      <div class="col-lg-12" *ngIf="loader==false">
        <div class="d-flex flex-wrap align-items-start mt-3 mb-4">
          <a *ngFor="let blog of Listnews" class="article" href="/{{blog.Id}}/{{generateBlogLink(blog.Title)}}">
            <img src="{{blog.Base64}}" *ngIf="blog.Base64!=null">
            <img src="assets/img/no-image.png" *ngIf="blog.Base64==null">
            <div class="post-content">
              <span class="cat">{{blog.DescriptionblogCategory}}</span>
              <h2>{{blog.Title}}</h2>
              <p> {{blog.Subtitle}}</p>
              <hr>
              <div class="d-flex mt-4 justify-content-between align-items-center">
                <h5 class="datee">{{blog.DtInsert | date: 'dd/MM/yyyy'}}</h5>
                <a class="btnx">Leggi tutto</a>
              </div>
            </div>
            <div class="clearfix"></div>
          </a>
        </div>
        <div class="d-flex flex-wrap justify-content-center align-items-center mt-2 mb-5"
          *ngIf="Listnews.length!=0 && pageLimit>1">
          <!-- <select [(ngModel)]="pageSize" (change)="chnageSizePage(pageSize)" style="width: 216px !important"
            class="mb-0 mr-2 mr-md-4">
            <option value="8">Elementi visualizzati 8</option>
            <option value="16" *ngIf="16<totalblogs">Elementi visualizzati 16</option>
            <option value="24" *ngIf="24<totalblogs">Elementi visualizzati 24</option>
            <option value="32" *ngIf="32<totalblogs">Elementi visualizzati 32</option>
            <option value="500">Tutti</option>
          </select> -->
          <div class="pagination mt-2 mt-lg-0 align-items-center">

            <a *ngIf="(pageIndex+1)>1" rel="prev" href="/pagina/{{pageIndex}}">
              <img src="assets/img/arrow-l.svg" alt="arrow" height="18" width="19" class="my-0 m-2">
            </a>

            <span><b>Pagina {{pageIndex + 1}}</b> di {{pageLimit}}</span>

            <a *ngIf="(pageIndex+1)<pageLimit" rel="next" href="/pagina/{{(pageIndex+2)}}">
              <img src="assets/img/arrow-r.svg" height="18" width="19" class="my-0 m-2">
            </a>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
