import { Component, OnInit } from '@angular/core';
import { Service } from '../service.service';
import { Meta, Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent {

  constructor(public service: Service, private title: Title, private metaService: Meta, @Inject(PLATFORM_ID) private platformId: Object,private route: ActivatedRoute,private router:Router) { }

  ngOnInit() {
    this.loader = true;
    // this.service.getAppsettings();
    // setTimeout(() => {
      var date = moment(new Date(), "YYYY-MM-DD");
      var hour = new Date().getHours();
      if (isPlatformBrowser(this.platformId)) {
        if (localStorage.getItem("tokenBlog") !== null) {
          var DateToken = JSON.parse(localStorage.getItem("DtTokenBlog")); // Check the key here
          if (DateToken && moment(DateToken, 'YYYY-MM-DD') > date) {
            this.service.updatetoken(JSON.parse(localStorage.getItem("tokenBlog")));
            this.main();
          } else if (DateToken && moment(DateToken, 'YYYY-MM-DD') === date) {
            if (new Date(DateToken).getHours() > hour) {
              this.service.updatetoken(JSON.parse(localStorage.getItem("tokenBlog")));
              this.main();
            } else {
              this.getToken();
            }
          } else {
            this.getToken();
          }
        } else {
          this.getToken();
        }
      } else {
        this.getToken();
      }

       //SEO
       this.title.setTitle('ITD | Blog');
       this.metaService.addTag({ name: 'title', content:'ITD | Blog | Marketing, Comunicazione e Tecnologia'});
       this.metaService.addTag({ property: 'og:title', content:'ITD | Blog | Marketing, Comunicazione e Tecnologia' });
       this.metaService.addTag({ name: 'description', content: 'Leggi il blog di ITD per approfondimenti su Marketing, Comunicazione e Tecnologia e rimani aggiornato sulle ultime tendenze e innovazioni del settore.' });
       this.metaService.addTag({ property: 'og:description', content: 'Leggi il blog di ITD per approfondimenti su Marketing, Comunicazione e Tecnologia e rimani aggiornato sulle ultime tendenze e innovazioni del settore.' });
       // Fin SEO
      // }, 300);
  }


  token: any = [];
  getToken() {
    this.service.getToken().subscribe(
      res => {
        this.token = res;
        this.service.updatetoken(this.token.Token);
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("tokenBlog", JSON.stringify(this.token.Token));
          localStorage.setItem("DtTokenBlog", JSON.stringify(moment(this.token.DtExpire.substring(0, 15), 'YYYY-MM-DD:hh:mm')));
        }
          this.main();
      }, error => {
        // You can access status:
        console.log(error.message);
      }
    )
  }

  main() {
    this.GetTotalblogs();
    this.GetCategoriesblogs();
  }

  totalArticle = 0;
  searchValue = '';
  dateFiltred = 'desc';
  currentYear = new Date().getFullYear();
  yearSelected = '';

  search = '';

  Listnews: any = [];
  NumBlogCategory: any = [];
  totalblogs = 0;
  GetTotalblogs() {
    this.totalblogs = 0;
    this.service.GetNumBlogCategory(this.blogCat, 0, 150, this.yearSelected, this.dateFiltred, this.searchValue).subscribe(
      res => {
        this.NumBlogCategory = res;
        for (let i = 0; i < this.NumBlogCategory.length; i++) {
          this.totalblogs += this.NumBlogCategory[i]?.TotalBlog;
        }

        if (this.NumBlogCategory.length == 0) {
          this.totalblogs = 0;
        }
        if ((this.totalblogs / this.pageSize) % 1 != 0) {
          this.pageLimit = Math.trunc(this.totalblogs / this.pageSize) + 1;
        } else {
          this.pageLimit = 1;
          this.pageIndex = 1;
        }
        this.GetNews();
      }
    )
  }

  GetNews() {
    this.route.paramMap.subscribe(params => {
      var page = params.get('numpage');
      if (+page>this.pageLimit) {
        this.router.navigate(['']);
      }
      if (page!=null) {
        this.pageIndex = +page - 1;
        this.service.GetBlogs(this.blogCat, this.pageSize * (+page-1), this.pageSize, this.yearSelected, this.dateFiltred, this.searchValue).subscribe(
          res => {
            this.Listnews = res;
            this.loader = false;
          }
        )
      }else{
        this.service.GetBlogs(this.blogCat, this.Offset, this.pageSize, this.yearSelected, this.dateFiltred, this.searchValue).subscribe(
          res => {
            this.Listnews = res;
            this.loader = false;
          }
        )
      }
    })
  }

  Categoriesblogs: any = [];
  blogCat = '';
  GetCategoriesblogs() {
    this.service.GetCategoriesblogs().subscribe(
      res => {
        this.Categoriesblogs = res;
      }
    )
  }

  pageIndex: number = 0;
  pageLimit: number = 0;
  pageSize: number = 9;
  Offset: number = 0;
  loader: boolean = false;

  // nextPage() {
  //   if (this.pageIndex < this.pageLimit) {
  //     this.pageIndex += 1;
  //     this.Offset = this.Offset + this.pageSize;
  //     this.loader = true;
  //     this.service.GetBlogs(this.blogCat, this.Offset, this.pageSize, this.yearSelected, this.dateFiltred, this.searchValue).subscribe(
  //       res => {
  //         this.Listnews = res;
  //         this.loader = false;
  //       }
  //     )
  //     if (isPlatformBrowser(this.platformId)) {
  //       window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  //     }
  //   }
  // }
  // previousPage() {
  //   if (this.pageIndex > 1) {
  //     this.pageIndex -= 1
  //     this.Offset = this.Offset - this.pageSize;
  //     this.loader = true;
  //     this.service.GetBlogs(this.blogCat, this.Offset, this.pageSize, this.yearSelected, this.dateFiltred, this.searchValue).subscribe(
  //       res => {
  //         this.Listnews = res;
  //         this.loader = false;
  //       }
  //     )
  //     if (isPlatformBrowser(this.platformId)) {
  //       window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  //     }
  //   }
  // }

  // chnageSizePage(i) {
  //   this.pageSize = parseInt(i);
  //   this.pageIndex = 1;
  //   if ((this.totalblogs / this.pageSize) % 1 != 0) {
  //     this.pageLimit = Math.trunc(this.totalblogs / this.pageSize) + 1;
  //   }
  //   this.GetNews();
  //   if (isPlatformBrowser(this.platformId)) {
  //     window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  //   }
  // }

  openmenu:boolean=false;

  generateBlogLink(blogTitle) {
    // Normalize and remove diacritics
    const normalizedTitle = blogTitle.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Apply your transformations for URL-safe format
    const formattedTitle = normalizedTitle.replace(/[^\w\s-]/g, '') // Remove non-alphanumeric characters except spaces and hyphens
                                          .replace(/\s+/g, '-') // Replace spaces with hyphens
                                          .replace(/--+/g, '-') // Replace consecutive hyphens with a single hyphen
                                          .replace(/^-+|-+$/g, '') // Remove leading or trailing hyphens
                                          .toLowerCase(); // Convert to lowercase

    const encodedTitle = encodeURIComponent(formattedTitle); // Encode for URL
    return encodedTitle;
}

}


